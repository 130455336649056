<template>
  <span
    v-tooltip="{
      content: $t(hint),
      placement: 'right-start',
      classes: ['u-tooltip', 'q-tooltip-w300', 'u-typography-helvetica u-text u-text--xs'],
      offset: -12
    }"
    class="info-tooltip"
    @click.stop
  >
    <u-icon class="info-tooltip__icon" name="help-circle" />
  </span>
</template>

<script>
import { UIcon } from 'universkin-shared';

export default {
  name: 'InfoTooltip',
  components: { UIcon },
  props: {
    hint: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.info-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 40px;
  min-height: 40px;

  &__icon {
    --u-icon-color: var(--u-color-grey-400);
  }

  &:hover {
    .info-tooltip__icon {
      --u-icon-color: var(--u-color-grey-900);
    }
  }
}
</style>
