<template>
  <div class="question-error-wrap">
    <slot></slot>
    <div role="alert" class="question-error-wrap__error q-mt8">{{ $t(error) }}</div>
  </div>
</template>

<script>
export default {
  name: 'QuestionWithErrorWrap',
  props: {
    error: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors.scss';

.question-error-wrap {
  &__error {
    font-family: var(--u-font-family-Gilroy);
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.4px;
    color: $color-error;
  }
}
</style>
