<template>
  <span class="ai-selected-tag">{{ $t('questionnaire.aiTag') }}</span>
</template>

<script>
export default {
  name: 'AiSelectedTag'
};
</script>

<style lang="scss" scoped>
.ai-selected-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14px;
  border-radius: 4px;
  background-color: var(--u-color-info);
  color: var(--u-color-white);
  box-sizing: border-box;
  padding: 0 6px;
  font-family: var(--u-font-family-Helvetica);
  font-weight: bold;
  font-size: 9px;
  line-height: 18px;
}
</style>
