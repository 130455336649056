<template>
  <div class="face-zone-multi-select flex flex-column flex--align-center">
    <h2 class="question-title u-typography-helvetica u-text u-text--s q-mb8 full-width">
      {{ $t(title) }}
    </h2>
    <span
      v-if="hint"
      class="face-zone-multi-select__hint u-typography-helvetica u-text u-text--xs q-mb8 full-width"
      >{{ $t(hint) }}</span
    >
    <div class="answer flex flex-column flex--align-center full-width">
      <div
        class="options answer__options q-mt16 full-width"
        role="group"
        :aria-label="$t(title)"
        data-question
        tabindex="-1"
      >
        <div
          v-for="option of options"
          :key="option.value"
          class="options__option q-mb8 full-width flex flex-column flex--align-center"
        >
          <q-checkbox
            :checked="getIsChecked(option.value)"
            :dysfunction="dysfunction"
            :text="option.text"
            @change="onChange(option.value, $event)"
          />
        </div>
      </div>
      <face-zone-selection-picture
        class="answer__picture q-mt24"
        :value="value"
        :gender="gender"
        :field-name="fieldName"
      />
    </div>
  </div>
</template>

<script>
import FaceZoneSelectionPicture from '@/modules/questionnaire/new-design-components/questions/face-zone-input/FaceZoneSelectionPicture';
import QCheckbox from '@/modules/questionnaire/new-design-components/inputs/QCheckbox';

import { COLOR_NAME } from '@/modules/questionnaire/new-design-components/questions/face-zone-input/faceZoneInput';

export default {
  name: 'FaceZoneMultiSelect',
  components: { QCheckbox, FaceZoneSelectionPicture },
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    fieldName: {
      type: String,
      required: true
    },
    hint: {
      type: String,
      default: ''
    },
    gender: {
      type: String,
      required: true
    }
  },
  computed: {
    dysfunction() {
      return COLOR_NAME[this.fieldName];
    }
  },
  methods: {
    onChange(value, isChecked) {
      if (isChecked) {
        this.$emit('input', [...this.value, value]);

        return;
      }

      this.$emit(
        'input',
        this.value.filter(val => val !== value)
      );
    },
    getIsChecked(value) {
      return this.value.includes(value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors.scss';
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.face-zone-multi-select {
  width: 100%;
  text-align: center;

  &__hint {
    color: $color-grey400;
  }
}

@media (min-width: $desktop-start) {
  .answer {
    flex-direction: row;

    &__picture {
      flex: 0 0 auto;
      order: -1;
      margin-right: 32px;
    }
  }
}
</style>
