<template>
  <div class="face-zone-selection" :class="containerClasses">
    <div class="zone-image">
      <img class="zone-image__image" :src="faceImage" />
      <img class="zone-image__image" :src="allZonesInactiveImage" />
      <template v-if="value">
        <img
          v-for="zoneImage of activeZonesImages"
          :key="zoneImage"
          class="zone-image__image"
          :src="zoneImage"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { FIELD_NAME } from '@/modules/questionnaire/new-design-components/questions/face-zone-input/faceZoneInput';

const FACE_SUB_DIRECTORY = {
  WITH_NECK: 'with-neck',
  WITHOUT_NECK: 'without-neck'
};

export default {
  name: 'FaceZoneSelectionPicture',
  props: {
    value: {
      type: [String, Array],
      required: true
    },
    gender: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      required: true
    }
  },
  computed: {
    containerClasses() {
      return { 'face-zone-selection--with-neck': this.isImageWithNeck };
    },
    isImageWithNeck() {
      return this.fieldName === FIELD_NAME.SAGGING;
    },
    faceImageSubDirectory() {
      if (this.isImageWithNeck) {
        return FACE_SUB_DIRECTORY.WITH_NECK;
      }

      return FACE_SUB_DIRECTORY.WITHOUT_NECK;
    },
    faceImage() {
      return require(`@/modules/questionnaire/assets/images/face-zone-select/face-image/${this.faceImageSubDirectory}/${this.gender}.svg`);
    },
    allZonesInactiveImage() {
      return require(`@/modules/questionnaire/assets/images/face-zone-select/zones/${this.fieldName}/all-zones-inactive.svg`);
    },
    activeZonesImages() {
      if (!Array.isArray(this.value)) {
        return [this.getActiveZoneImageByValue(this.value)];
      }

      return this.value.map(this.getActiveZoneImageByValue);
    }
  },
  methods: {
    getActiveZoneImageByValue(value) {
      return require(`@/modules/questionnaire/assets/images/face-zone-select/zones/${this.fieldName}/zones-active/${value}.svg`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors.scss';

.face-zone-selection {
  width: 132px;
  height: 187px;

  &--with-neck {
    height: 238px;
  }
}

.zone-image {
  position: relative;
  width: 100%;

  &__image {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
}
</style>
