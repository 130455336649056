<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['comedoLocations'])">
      <face-zone-multi-select
        title="label.bumpsLocations"
        hint="label.selectAllRegionsThatApply"
        :gender="gender"
        :value="comedoLocations"
        :options="$options.comedoLocationsOptions"
        field-name="texture"
        @input="onFieldChange('comedoLocations', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import FaceZoneMultiSelect from '@/modules/questionnaire/new-design-components/questions/face-zone-input/FaceZoneMultiSelect';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { CAMEDO_LOCATIONS_OPTIONS } from '@/modules/questionnaire/constants/steps/skinTexture';

export default {
  name: 'BumpsLocationTemplate',
  components: { FaceZoneMultiSelect, QuestionWithErrorWrap },
  mixins: [stepTemplateMixin],
  comedoLocationsOptions: CAMEDO_LOCATIONS_OPTIONS,
  props: {
    gender: {
      type: String,
      required: true
    },
    comedoLocations: {
      type: Array,
      default: () => []
    }
  }
};
</script>
