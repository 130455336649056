export const FIELD_NAME = {
  SAGGING: 'sagging',
  REDNESS: 'redness',
  BLOOD_VESSELS: 'blood-vessels',
  DRYNESS: 'dryness',
  OILINESS: 'oiliness',
  TEXTURE: 'texture',
  PIGMENTATION: 'pigmentation'
};

export const COLOR_NAME = {
  [FIELD_NAME.SAGGING]: 'skin-appearance',
  [FIELD_NAME.REDNESS]: 'skin-redness',
  [FIELD_NAME.BLOOD_VESSELS]: 'skin-redness',
  [FIELD_NAME.DRYNESS]: 'skin-dryness',
  [FIELD_NAME.OILINESS]: 'skin-oiliness',
  [FIELD_NAME.TEXTURE]: 'skin-texture',
  [FIELD_NAME.PIGMENTATION]: 'skin-pigmentation'
};
