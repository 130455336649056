import { get } from 'lodash';

export const stepTemplateMixin = {
  props: {
    fieldErrors: {
      type: Object,
      default: null
    }
  },
  methods: {
    onFieldChange(fieldName, value) {
      this.$emit('field-change', { fieldName, value });
    },
    getFieldsError(fieldNames) {
      const firstFieldError = fieldNames
        .map(fieldName => get(this.fieldErrors, [fieldName, 0], ''))
        .find(fieldError => fieldError);

      return firstFieldError || '';
    }
  }
};

export default stepTemplateMixin;
